/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import type * as api from "@aws-amplify/api";

/**
 * Using any type since we're only concerned with the errors in this function
 */
export const formatGraphQlErrors = (
  response: api.GraphQLResult<any>
): string => {
  return response.errors?.map((error) => error.message).join(", ") || "";
};
