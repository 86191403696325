/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import * as React from "react";
import * as AwsUI from "@awsui/components-react";

interface Props {
  error: string;
  login: () => void;
}

export const Unauthenticated: React.FC<Props> = ({ error, login }) => {
  return (
    <AwsUI.SpaceBetween direction="vertical" size="s">
      <div>
        {error && (
          <AwsUI.Flashbar
            items={[
              {
                type: "error",
                content: error,
                header: "Error",
                onButtonClick: login,
                buttonText: "Login",
              },
            ]}
          />
        )}
      </div>

      <AwsUI.Container>
        <AwsUI.SpaceBetween direction="vertical" size="s">
          <AwsUI.Header variant="h1">Unauthorized</AwsUI.Header>

          <AwsUI.TextContent>
            You must be logged in to use this application.
          </AwsUI.TextContent>

          <AwsUI.Button onClick={login} variant="primary">
            Login
          </AwsUI.Button>
        </AwsUI.SpaceBetween>
      </AwsUI.Container>
    </AwsUI.SpaceBetween>
  );
};
