/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import * as React from "react";
import * as AwsUI from "@awsui/components-react";
import * as awsUIDesignTokens from "@awsui/design-tokens";
import * as xstate from "@xstate/react";

import * as machines from "src/machines";
import * as lib from "src/lib";
import * as contexts from "src/contexts";

type FilesMetadataStatus = "ready" | "uploading" | "rejected";

interface FilesMetadataProps {
  files: File[];
  status: FilesMetadataStatus;
}

const FilesMetadata: React.FC<FilesMetadataProps> = ({ files, status }) => {
  const statusMappings: Record<
    FilesMetadataStatus,
    AwsUI.StatusIndicatorProps["type"]
  > = {
    ready: "success",
    uploading: "in-progress",
    rejected: "error",
  };

  return (
    <AwsUI.Cards
      items={files}
      cardDefinition={{
        header: (file: File) => {
          const statusType =
            file.size > machines.FILE_SIZE_MAX_BYTES
              ? "error"
              : statusMappings[status];

          return (
            <div>
              <AwsUI.StatusIndicator type={statusType} />
              {lib.escapePdfDocumentName(file.name)}

              {file.name.length > lib.MAX_FILE_NAME_CHARS ? (
                <div style={{ marginLeft: ".5rem" }}>
                  <AwsUI.Popover>
                    This file name was shortened because it has more than{" "}
                    {lib.MAX_FILE_NAME_CHARS}.
                  </AwsUI.Popover>
                </div>
              ) : (
                file.size > machines.FILE_SIZE_MAX_BYTES && (
                  <div
                    style={{
                      color: awsUIDesignTokens.colorTextBodySecondary,
                      fontSize: "1.2rem",
                    }}
                  >
                    This file will not be uploaded because it is over the max
                    file size limit of{" "}
                    {lib.prettify.fileSize(machines.FILE_SIZE_MAX_BYTES)}.
                  </div>
                )
              )}
            </div>
          );
        },
        sections: [
          {
            header: "Size",
            content: (file: File) => {
              return lib.prettify.fileSize(file.size);
            },
          },
          {
            header: "Last modified",
            content: (file: File) => {
              return lib.prettify.dateTime(new Date(file.lastModified));
            },
          },
        ],
      }}
    />
  );
};

export const DocumentUpload: React.FC<{}> = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { user } = React.useContext(contexts.auth);

  const teamOptions: NonNullable<AwsUI.RadioGroupProps["items"]> =
    React.useMemo(() => {
      const defaultOptions: NonNullable<AwsUI.RadioGroupProps["items"]> = [];

      const safeGroupsPayload =
        user?.signInUserSession.idToken.payload["custom:groups"] || "";

      const options = lib
        .commaDelimitedStringToJson(safeGroupsPayload)
        .reduce(
          (
            result: NonNullable<AwsUI.RadioGroupProps["items"]>,
            teamOrRoleAttribute: string
          ) => {
            const cognitoTeamAttributeInfo =
              lib.getCognitoGroupAttributeInfo(teamOrRoleAttribute);

            if ("team" in cognitoTeamAttributeInfo) {
              const { team, sourceLanguage, destinationLanguage } =
                cognitoTeamAttributeInfo;

              if (team === lib.IT_ADMIN_TEAM) {
                return result;
              }

              const label = `${team} (${lib.prettify.language(
                sourceLanguage
              )} to ${lib.prettify.language(destinationLanguage)})`;

              return [...result, { label, value: teamOrRoleAttribute }];
            }

            return result;
          },
          []
        );

      return user ? options : defaultOptions;
    }, [user]);

  const [current, send] = xstate.useMachine(
    machines.createDocumentUpload(teamOptions)
  );

  const statusMappings: Record<
    machines.FileUploaderStates,
    FilesMetadataStatus
  > = {
    selecting: "ready",
    uploading: "uploading",
    uploadRejected: "rejected",
  };

  const openFileFinder = () => {
    inputRef.current?.click();
  };

  const formValid = !!(
    current.context.files.length > 0 && current.context.selectedTeam
  );

  /**
   * A little hacky, but this gives us better type inference. XState has a weird type for the state value even though it's a string
   */
  const stateString: machines.FileUploaderStates =
    current.value as unknown as machines.FileUploaderStates;

  return (
    <main>
      <AwsUI.SpaceBetween size="s" direction="vertical">
        <div>
          {
            <AwsUI.Flashbar
              items={
                [
                  current.context.message && {
                    type: current.context.message.type,
                    header:
                      current.context.message.type === "error"
                        ? "Error"
                        : "Success",
                    content: current.context.message.text,
                  },
                ].filter(Boolean) as AwsUI.FlashbarProps["items"]
              }
            />
          }
        </div>

        <AwsUI.Container>
          <AwsUI.Form
            actions={
              <AwsUI.SpaceBetween direction="horizontal" size="xs">
                <AwsUI.Button
                  variant="link"
                  href="/documents"
                  disabled={!current.context.files.length}
                >
                  Cancel
                </AwsUI.Button>

                <AwsUI.Button
                  variant="primary"
                  disabled={!formValid}
                  loading={current.matches("uploading")}
                  onClick={() => {
                    send({ type: "UPLOAD" });
                  }}
                >
                  Submit
                </AwsUI.Button>
              </AwsUI.SpaceBetween>
            }
            header={<AwsUI.Header variant="h1">Upload Documents</AwsUI.Header>}
          >
            <AwsUI.SpaceBetween size="m" direction="vertical">
              <AwsUI.FormField label="Team">
                {teamOptions.length ? (
                  <AwsUI.RadioGroup
                    value={current.context.selectedTeam}
                    items={teamOptions}
                    onChange={({ detail: { value } }) => {
                      send({
                        type: "SET_TEAM",
                        team: value,
                      });
                    }}
                    ariaRequired
                  />
                ) : (
                  <p>You're not a part of any teams.</p>
                )}
              </AwsUI.FormField>

              <AwsUI.FormField
                label="Documents"
                constraintText="Only PDF files are supported. Special characters will be removed from the file name."
              >
                <AwsUI.Button onClick={openFileFinder}>
                  <AwsUI.Icon name="upload" /> Choose files
                </AwsUI.Button>

                <input
                  key={JSON.stringify(current.context.files)}
                  type="file"
                  style={{ visibility: "hidden" }}
                  ref={inputRef}
                  accept=".pdf,application/pdf"
                  multiple
                  onChange={({ target: { files } }) => {
                    if (files) {
                      send({ type: "SET_FILES", files });
                    }
                  }}
                />
              </AwsUI.FormField>

              <FilesMetadata
                files={current.context.files}
                status={statusMappings[stateString]}
              />
            </AwsUI.SpaceBetween>
          </AwsUI.Form>
        </AwsUI.Container>
      </AwsUI.SpaceBetween>
    </main>
  );
};
