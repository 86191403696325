/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/
import * as auth from "@aws-amplify/auth";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const getIdToken = async () => ({
  Authorization: (await auth.Auth.currentSession()).getIdToken().getJwtToken(),
});

export const formatAmplifyConfig = (amplifyConfig: Record<string, any>) => {
  try {
    // Assuming you have two redirect URIs, and the first is for localhost and second is for production
    const [localRedirectSignIn, productionRedirectSignIn] =
      amplifyConfig.Auth.oauth.redirectSignIn;

    const [localRedirectSignOut, productionRedirectSignOut] =
      amplifyConfig.Auth.oauth.redirectSignOut;

    return {
      ...amplifyConfig,
      API: { ...amplifyConfig.API, graphql_headers: getIdToken },
      Auth: {
        ...amplifyConfig.Auth,
        oauth: {
          ...amplifyConfig.Auth.oauth,
          redirectSignIn: isLocalhost
            ? localRedirectSignIn
            : productionRedirectSignIn,
          redirectSignOut: isLocalhost
            ? localRedirectSignOut
            : productionRedirectSignOut,
        },
      },
    };
  } catch (error) {
    console.error("Failed to parse amplify config.", error);

    throw error;
  }
};
