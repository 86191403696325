/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

/**
 * If making changes to this file, make sure to update in `@ai-document-translation/lib` as well
 */
import {
  LANGUAGE_DELIMITER,
  TEAM_ATTRIBUTE_STRING_DELIMITER,
} from "../getCognitoGroupAttributeInfo/getCognitoGroupAttributeInfo.lib";
import type * as types from "src/types";

export const getCognitoTeam = ({
  team,
  sourceLanguage,
  destinationLanguage,
}: {
  team: string;
  sourceLanguage: types.Language;
  destinationLanguage: types.Language;
}): string => {
  return `${team}${TEAM_ATTRIBUTE_STRING_DELIMITER}${sourceLanguage}${LANGUAGE_DELIMITER}${destinationLanguage}`;
};
