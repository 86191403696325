/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import * as xstate from "xstate";

type Data = any;

interface Context {
  data: Data;
  error: string;
}

interface StateSchema {
  states: { idle: {}; fetching: {}; resolved: {}; rejected: {} };
}

interface Fetch {
  type: "FETCH";
  fetchFn: (...args: any[]) => Promise<Data>;
}

interface Retry {
  type: "RETRY";
}

type DoneInvokeError = xstate.DoneInvokeEvent<Error>;

type DoneInvokeData = xstate.DoneInvokeEvent<Data>;

type Event = Fetch | Retry | DoneInvokeError | DoneInvokeData;

const fetchData = async (_context: Context, event: Event) => {
  if ("fetchFn" in event) {
    return await (event as Fetch).fetchFn();
  }
};

const updateData = xstate.assign<Context, Event>({
  data: (_context: Context, event: DoneInvokeData) => {
    if ("data" in event) {
      return event.data;
    }
  },
});

const updateError = xstate.assign<Context, Event>({
  error: (_context: Context, event: Event) => {
    if ("data" in event) {
      return event.data.message;
    }
  },
});

export const DataFetcher = xstate.Machine<Context, StateSchema, Event>(
  {
    id: "DataFecther",
    initial: "idle",
    states: {
      idle: { on: { FETCH: { target: "fetching" } } },
      fetching: {
        invoke: {
          src: "fetchData",
          onDone: { target: "resolved", actions: ["updateData"] },
          onError: { target: "rejected", actions: ["updateError"] },
        },
      },
      resolved: { type: "final" },
      rejected: { on: { RETRY: { target: "fetching" } } },
    },
  },
  { services: { fetchData }, actions: { updateData, updateError } }
);
