/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import * as xstate from "xstate";

interface Context {
  open: boolean;
}

interface StateSchema {
  states: { idle: {} };
}

interface Open {
  type: "OPEN";
}

interface Close {
  type: "CLOSE";
}

interface Toggle {
  type: "TOGGLE";
}

type Event = Open | Close | Toggle;

const open = xstate.assign<Context, Event>({
  open: () => {
    return true;
  },
});

const close = xstate.assign<Context, Event>({
  open: () => {
    return false;
  },
});

const toggle = xstate.assign<Context, Event>({
  open: (context: Context) => {
    return !context.open;
  },
});

interface Params {
  initialOpen: Context["open"];
}

export const createToggler = ({ initialOpen = false }: Params) =>
  xstate.Machine<Context, StateSchema, Event>(
    {
      id: "Toggler",
      initial: "idle",
      context: { open: initialOpen },
      states: {
        idle: {
          on: {
            CLOSE: { actions: ["close"] },
            OPEN: { actions: ["open"] },
            TOGGLE: { actions: ["toggle"] },
          },
        },
      },
    },
    { actions: { open, close, toggle } }
  );
