/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import type * as api from "@aws-amplify/api";

import { Base } from "../Base/Base.model";
import type * as types from "src/types";

export class DocumentMetadata extends Base {
  private static query = {
    get: /* GraphQL */ `
      query GetDocumentMetadata($id: ID!) {
        getDocumentMetadata(id: $id) {
          id
          name
          status
        }
      }
    `,
    list: /* GraphQL */ `
      query ListDocumentMetadatas(
        $nameQuery: String
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection = DESC
        $team: String
      ) {
        documentMetadatas(
          nameQuery: $nameQuery
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
          team: $team
        ) {
          nextToken
          items {
            id
            createdAt
            updatedAt
            sourceLanguage
            destinationLanguage
            downloadKey
            name
            status
            statusHistory {
              errorMsg
              statusData
              status
            }
          }
        }
      }
    `,
  };

  public static get(
    id: string
  ): Promise<api.GraphQLResult<null | DocumentMetadata>> {
    return DocumentMetadata.fetch<null | DocumentMetadata>({
      query: DocumentMetadata.query.get,
      variables: { id },
    });
  }

  public static list({
    nameQuery,
    team,
    limit,
    nextToken,
    sortDirection,
  }: {
    nameQuery?: string;
    team?: string;
    limit?: number;
    nextToken?: string | null;
    sortDirection?: types.SortDirection;
  } = {}): Promise<api.GraphQLResult<DocumentMetadata[]>> {
    return DocumentMetadata.fetch<DocumentMetadata[]>({
      query: DocumentMetadata.query.list,
      variables: { nameQuery, team, limit, nextToken, sortDirection },
    });
  }
}
