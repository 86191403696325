/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import * as xstate from "xstate";
import type * as AwsUI from "@awsui/components-react";
import type * as api from "@aws-amplify/api";
import * as auth from "@aws-amplify/auth";

import type * as types from "src/types";
import * as models from "src/models";
import * as lib from "src/lib";

export const DEFAULT_RESULTS_PER_PAGE = 25;
const RESULTS_PER_PAGE_KEY = "resultsPerPage";
const NAME_QUERY_DEBOUNCE_DELAY_IN_MILLI = 500;

// Format is resultsPerPage-query
type NextTokenId = string;
type PageNumber = number;
type NextToken = string | null;

interface Context {
  credentials: {
    secretAccessKey: string;
    accessKeyId: string;
    sessionToken: string;
  };
  documents: types.Document[];
  fetchError: string;
  sorting: {
    column: AwsUI.TableProps["sortingColumn"];
    descending: AwsUI.TableProps["sortingDescending"];
  };
  selection: {
    document: types.Document | null;
    team: AwsUI.SelectProps["selectedOption"] | null;
  };
  filtering: { query: string };
  pagination: {
    currentPage: number;
    totalKnownPages: number;
    resultsPerPage: number;
    // Next tokens for each page are stored in an object so we can paginate
    nextTokens: Record<NextTokenId, Record<PageNumber, NextToken>>;
    reachedLastPage: Record<NextTokenId, boolean>;
    openEnd: boolean;
  };
}

interface StateSchema {
  states: { idle: {}; fetching: {}; fetchingFailed: {}; selecting: {} };
}

interface SelectDocument {
  type: "SELECT_DOCUMENT";
  document: types.Document;
}

interface SelectTeam {
  type: "SELECT_TEAM";
  team: AwsUI.SelectProps["selectedOption"];
}

interface ChangeFilteringQuery {
  type: "CHANGE_FILTERING_QUERY";
  query: string;
}

interface ToggleSorting {
  type: "TOGGLE_SORTING";
  column: AwsUI.TableProps["sortingColumn"];
  descending: AwsUI.TableProps["sortingDescending"];
}

interface FetchDocuments {
  type: "FETCH_DOCUMENTS";
}

interface ChangePreferences {
  type: "CHANGE_PREFERENCES";
  resultsPerPage: number;
}

interface ChangeCurrentPage {
  type: "CHANGE_CURRENT_PAGE";
  currentPage: number;
}

interface Refetch {
  type: "REFETCH";
}

type DoneFetching = xstate.DoneInvokeEvent<{
  documents: {
    data: {
      documentMetadatas: {
        nextToken: string | null;
        items: types.Document[];
        errors: string[];
      };
    };
  };
  credentials: Context["credentials"];
}>;

type Event =
  | SelectDocument
  | SelectTeam
  | ToggleSorting
  | ChangeFilteringQuery
  | FetchDocuments
  | DoneFetching
  | ChangePreferences
  | ChangeCurrentPage
  | Refetch;

export const toNextTokenId = ({
  resultsPerPage,
  query,
  team,
}: {
  resultsPerPage: Context["pagination"]["resultsPerPage"];
  query: Context["filtering"]["query"];
  team: string;
}) => {
  return `${resultsPerPage}-${query}-${team}`;
};

const selectDocument = xstate.assign<Context, Event>({
  selection: (context, event) => {
    const { document } = event as SelectDocument;

    return { ...context.selection, document };
  },
});

const selectTeam = xstate.assign<Context, Event>({
  selection: (context, event) => {
    const { team } = event as SelectTeam;

    return { ...context.selection, team };
  },
});

const toggleSorting = xstate.assign<Context, Event>({
  sorting: (_context, event) => {
    const { column, descending } = event as ToggleSorting;

    return { column, descending };
  },
});

/**
 * When NOT filtering, our ability to sort is only on the createdAt column
 */
const setNonFilterSorting = xstate.assign<Context, Event>({
  sorting: (_context, event) => {
    return { column: { sortingField: "createdAt" }, descending: true };
  },
});

/**
 * When filtering, our ability to sort is only on the name column
 */
const setFilterSorting = xstate.assign<Context, Event>({
  sorting: (_context) => {
    return { column: { sortingField: "name" }, descending: false };
  },
});

const changeFilteringQuery = xstate.assign<Context, Event>({
  filtering: (_context, event) => {
    const { query } = event as ChangeFilteringQuery;

    return { query };
  },
});

const updatePagination = xstate.assign<Context, Event>({
  pagination: (context, event) => {
    const {
      data: {
        documents: {
          // Both graphql and xstate wrap the response in an object called `data`
          data: { documentMetadatas },
        },
      },
    } = event as DoneFetching;

    const nextTokenId = toNextTokenId({
      resultsPerPage: context.pagination.resultsPerPage,
      query: context.filtering.query,
      team: context.selection.team?.value || "",
    });

    if (documentMetadatas) {
      const { nextToken } = documentMetadatas;

      const existingNextTokens =
        context.pagination.nextTokens[nextTokenId] || {};

      const newNextToken = { [context.pagination.currentPage]: nextToken };

      const updatedNextTokens = {
        [nextTokenId]: {
          ...existingNextTokens,
          ...newNextToken,
        },
      };

      const nextTokens = {
        ...context.pagination.nextTokens,
        ...updatedNextTokens,
      };

      const reachedLastPage =
        context.pagination.reachedLastPage[nextTokenId] || nextToken === null;

      return {
        ...context.pagination,
        totalKnownPages:
          Object.keys(updatedNextTokens[nextTokenId] || {}).length || 1,
        nextTokens,
        reachedLastPage: {
          ...context.pagination.reachedLastPage,
          [nextTokenId]: reachedLastPage,
        },
        openEnd: !reachedLastPage,
      };
    }

    return context.pagination;
  },
});

const changePreferences = xstate.assign<Context, Event>({
  pagination: (context, event) => {
    const { resultsPerPage } = event as ChangePreferences;

    localStorage.setItem(RESULTS_PER_PAGE_KEY, resultsPerPage.toString());

    const nextTokenId = toNextTokenId({
      resultsPerPage,
      query: context.filtering.query,
      team: context.selection.team?.value || "",
    });

    const reachedLastPage =
      nextTokenId in context.pagination.reachedLastPage
        ? context.pagination.reachedLastPage[nextTokenId]
        : false;

    return {
      ...context.pagination,
      resultsPerPage,
      reachedLastPage: {
        ...context.pagination.reachedLastPage,
        [nextTokenId]: reachedLastPage,
      },
      openEnd: reachedLastPage,
    };
  },
});

const setFetchError = xstate.assign<Context, Event>({
  fetchError: (_context, event) => {
    const { data } = event as xstate.ErrorExecutionEvent;

    if (Array.isArray(data.errors)) {
      return lib.formatGraphQlErrors(data as api.GraphQLResult);
    }

    return data.toString();
  },
});

const clearFetchError = xstate.assign<Context, Event>({
  fetchError: (_context, _event) => {
    return "";
  },
});

const changeCurrentPage = xstate.assign<Context, Event>({
  pagination: (context, event) => {
    const { currentPage } = event as ChangeCurrentPage;

    return {
      ...context.pagination,
      currentPage,
    };
  },
});

const resetCurrentPage = xstate.assign<Context, Event>({
  pagination: (context) => {
    return {
      ...context.pagination,
      currentPage: 1,
    };
  },
});

const updateDocuments = xstate.assign<Context, Event>({
  documents: (_context, event) => {
    const {
      data: {
        documents: {
          // Both graphql and xstate wrap the response in an object called `data`
          data: {
            documentMetadatas: { items, errors },
          },
        },
      },
    } = event as DoneFetching;

    if (errors?.length) {
      /**
       * Xstate will pass our `updateFetchError` function with the full
       * payload so we can create our error message. We just need to throw
       * here since GraphQL doesn't throw and returns a safe errors array
       *
       */
      throw new Error();
    }

    return items;
  },
});

const updateCredentials = xstate.assign<Context, Event>({
  credentials: (_context, event) => {
    const {
      data: { credentials },
    } = event as DoneFetching;

    return credentials;
  },
});

const undefinedIfEmpty = (str: string) => {
  return str || undefined;
};

const DELAYED_DOCUMENTS_FETCH_ID = "delayed-documents-fetch";

const startDelayedDocumentsFetch = xstate.send<Context, Event>(
  {
    type: "FETCH_DOCUMENTS",
  },
  { delay: NAME_QUERY_DEBOUNCE_DELAY_IN_MILLI, id: DELAYED_DOCUMENTS_FETCH_ID }
);

const cancelDelayedDocumentsFetch = xstate.actions.cancel(
  DELAYED_DOCUMENTS_FETCH_ID
);

const fetchDocumentsAndCredentials = async (context: Context) => {
  const nextTokenId = toNextTokenId({
    resultsPerPage: context.pagination.resultsPerPage,
    query: context.filtering.query,
    team: context.selection.team?.value || "",
  });

  const team = context.selection.team?.value
    ? lib.getCognitoTeam(
        lib.getCognitoGroupAttributeInfo<lib.TeamInfo>(
          context.selection.team.value
        )
      )
    : undefined;

  const credentials = await auth.Auth.currentCredentials();
  const documents = await models.DocumentMetadata.list({
    nameQuery: undefinedIfEmpty(context.filtering.query),
    nextToken:
      context.pagination.nextTokens[nextTokenId] &&
      context.pagination.nextTokens[nextTokenId][
        context.pagination.currentPage - 1
      ],
    sortDirection: context.sorting.descending ? "DESC" : "ASC",
    limit: context.pagination.resultsPerPage,
    team,
  });

  return {
    documents,
    credentials,
  };
};

const resultsPerPagePreference = localStorage.getItem(RESULTS_PER_PAGE_KEY);

const initialResultsPerPage = resultsPerPagePreference
  ? Number(resultsPerPagePreference)
  : DEFAULT_RESULTS_PER_PAGE;

const initialQuery = "";
const initialTeam = "";

const initialNextTokenId = toNextTokenId({
  resultsPerPage: initialResultsPerPage,
  query: initialQuery,
  team: initialTeam,
});

export const DocumentList = xstate.Machine<Context, StateSchema, Event>(
  {
    id: "DocumentList",
    initial: "idle",
    context: {
      credentials: { accessKeyId: "", secretAccessKey: "", sessionToken: "" },
      documents: [],
      fetchError: "",
      selection: { document: null, team: null },
      sorting: { column: undefined, descending: true },
      pagination: {
        resultsPerPage: initialResultsPerPage,
        totalKnownPages: 1,
        currentPage: 1,
        nextTokens: {
          [initialNextTokenId]: {},
        },
        reachedLastPage: { [initialNextTokenId]: false },
        openEnd: true,
      },
      filtering: { query: initialQuery },
    },
    states: {
      idle: {
        on: { SELECT_TEAM: { actions: ["selectTeam"], target: "fetching" } },
      },
      fetching: {
        invoke: {
          src: "fetchDocumentsAndCredentials",
          onDone: {
            actions: [
              "updateDocuments",
              "updatePagination",
              "updateCredentials",
            ],
            target: "selecting",
          },
          onError: { target: "fetchingFailed", actions: ["setFetchError"] },
        },
      },
      fetchingFailed: {
        on: {
          REFETCH: { target: "fetching", actions: ["clearFetchError"] },
        },
      },
      selecting: {
        on: {
          SELECT_DOCUMENT: { actions: ["selectDocument"] },

          SELECT_TEAM: { actions: ["selectTeam"], target: "fetching" },
          TOGGLE_SORTING: {
            actions: ["toggleSorting", "resetCurrentPage"],
            target: "fetching",
          },
          CHANGE_PREFERENCES: {
            actions: ["changePreferences", "resetCurrentPage"],
            target: "fetching",
          },
          CHANGE_FILTERING_QUERY: {
            actions: [
              "changeFilteringQuery",
              "resetCurrentPage",
              "cancelDelayedDocumentsFetch",
              "startDelayedDocumentsFetch",
            ],
          },
          FETCH_DOCUMENTS: { target: "fetching" },
          CHANGE_CURRENT_PAGE: {
            target: "fetching",
            actions: ["changeCurrentPage"],
          },
          REFETCH: {
            target: "fetching",
          },
        },
      },
    },
  },
  {
    services: { fetchDocumentsAndCredentials },
    actions: {
      selectDocument,
      selectTeam,
      toggleSorting,
      changeFilteringQuery,
      setFetchError,
      clearFetchError,
      changeCurrentPage,
      resetCurrentPage,
      updateDocuments,
      updatePagination,
      changePreferences,
      cancelDelayedDocumentsFetch,
      startDelayedDocumentsFetch,
      updateCredentials,
    },
  }
);
