/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

export const MAX_FILE_NAME_CHARS = 128;

export const escapePdfDocumentName = (documentName: string): string => {
  const nonAzNumHyphenOrUnderscore = /[^a-zA-Z0-9_-\s](?!pdf$)/g;

  return documentName
    .replace(nonAzNumHyphenOrUnderscore, "")
    .slice(0, MAX_FILE_NAME_CHARS);
};
