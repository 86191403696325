/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import * as React from "react";
import type * as IAuth from "@aws-amplify/auth";
import type * as xstate from "xstate";

import * as lib from "src/lib";

type CommaDelimitedString = string;
type BooleanString = string;

export interface CognitoUser extends IAuth.CognitoUser {
  signInUserSession: {
    idToken: {
      payload: {
        "custom:groups": CommaDelimitedString;
        "custom:team": string;
        "custom:ES-EN": BooleanString;
        "custom:EN-ES": BooleanString;
        "custom:DE-EN": BooleanString;
        "custom:FR-EN": BooleanString;
      };
    };
  };
  attributes: { email: string };
}

interface Context {
  user: null | CognitoUser;
  error: string;
  login: () => void;
  logout: () => void;
  setUser: (user: CognitoUser | null) => void;
  clearUser: () => void;
  checkAuthentication: () => void;
  status: xstate.StateValue;
}

export const auth = React.createContext<Context>({
  user: null,
  error: "",
  login: lib.noop,
  logout: lib.noop,
  setUser: lib.noop,
  clearUser: lib.noop,
  checkAuthentication: lib.noop,
  status: "checkingAuthentication",
});
