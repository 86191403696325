/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import * as amplify from "aws-amplify";

import * as Components from "src/components";
import * as lib from "src/lib";
import * as amplifyConfig from "./aws-exports";

import "@awsui/global-styles/index.css";

const config = lib.formatAmplifyConfig(amplifyConfig.amplifyConfig);

amplify.Amplify.configure(config);

ReactDOM.render(
  <React.StrictMode>
    <Components.AuthProvider>
      <Components.App />
    </Components.AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
