/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

/**
 * If making changes to this file, make sure to update in `@ai-document-translation/lib` as well
 */
import type * as types from "src/types";

export const TEAM_ATTRIBUTE_STRING_DELIMITER = "--";
export const LANGUAGE_DELIMITER = "-";

export interface TeamInfo {
  team: string;
  sourceLanguage: types.Language;
  destinationLanguage: types.Language;
}

export interface RoleInfo {
  role: string;
}

export const getCognitoGroupAttributeInfo = <
  AttributeType extends TeamInfo | RoleInfo
>(
  groupAttributeString: string
): AttributeType => {
  const [, , teamOrRole, srcDestLangauges] = groupAttributeString.split(
    TEAM_ATTRIBUTE_STRING_DELIMITER
  );

  if (srcDestLangauges) {
    const [sourceLanguage, destinationLanguage] =
      srcDestLangauges.split(LANGUAGE_DELIMITER);

    return {
      team: teamOrRole,
      sourceLanguage: sourceLanguage as types.Language,
      destinationLanguage: destinationLanguage as types.Language,
    } as unknown as AttributeType;
  }

  return { role: teamOrRole } as unknown as AttributeType;
};
