/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import * as React from "react";
import * as AwsUI from "@awsui/components-react";
import * as ReactRouter from "react-router-dom";

import { routeMappings } from "../Routes/Routes.component";

interface Props {}

const rootItem = routeMappings[""];

const toBreadcrumbs = (
  location: ReactRouter.RouterChildContext["router"]["route"]["location"]
): AwsUI.BreadcrumbGroupProps["items"] => {
  const paths = location.pathname.split("/").filter(Boolean);

  if (paths.length) {
    return [
      rootItem as AwsUI.BreadcrumbGroupProps["items"][0],
      ...paths.reduce(
        (result: AwsUI.BreadcrumbGroupProps["items"], path: string) => {
          if (path in routeMappings) {
            return [
              ...result,
              {
                href: routeMappings[path].href as string,
                text: routeMappings[path].text,
              },
            ];
          }

          return result;
        },
        []
      ),
    ];
  }

  return [rootItem as AwsUI.BreadcrumbGroupProps["items"][0]];
};

export const Breadcrumbs: React.FC<Props> = () => {
  const location = ReactRouter.useLocation();

  const breadcrumbItems = React.useMemo(() => {
    return toBreadcrumbs(location);
  }, [location]);

  return (
    <AwsUI.BreadcrumbGroup items={breadcrumbItems} ariaLabel="Breadcrumbs" />
  );
};
