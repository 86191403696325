/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import * as amplify from "aws-amplify";
import * as api from "@aws-amplify/api";

/**
 * Base model that other models can extend from.
 */
export class Base {
  /**
   * Generic GraphQL fetcher with some error handling and better type assertions
   */
  protected static fetch<Result>({
    query,
    variables,
  }: {
    query: string;
    variables?: object;
  }): Promise<api.GraphQLResult<Result>> {
    try {
      return (amplify.API.graphql(
        {
          query,
          variables,
          authMode: api.GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        }
        // We're not using the observable version of this, so
        // casting type here so it'll propagate throughout app
      ) as unknown) as Promise<api.GraphQLResult<Result>>;
    } catch (error) {
      throw error;
    }
  }
}
