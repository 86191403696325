/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import type * as ILib from "@ai-document-translation/lib";

// Months are zero-indexed
type Month = string;

const monthMapping: Record<number, Month> = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "October",
  9: "November",
  10: "November",
  11: "December",
};

const stripTrailingZeroIfExists = (numString: string): string => {
  return numString.replace(/\.0$/, "");
};

export const prettify = {
  date: (date: Date): string => {
    const month = monthMapping[date.getMonth()];

    const day = date.getDate();

    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  },
  dateTime: (date: Date): string => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const month = monthMapping[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
  },
  dateUtc: (date: Date): string => {
    const month = monthMapping[date.getUTCMonth()];

    const day = date.getUTCDate();

    const year = date.getUTCFullYear();

    return `${month} ${day}, ${year}`;
  },
  fileSize(fileSizeInBytes: number): string {
    const KILOBYTE_BYTES = 1024;
    const MEGABYTE_BYTES = KILOBYTE_BYTES * 1000;

    if (fileSizeInBytes < MEGABYTE_BYTES) {
      const value = Math.round(fileSizeInBytes / KILOBYTE_BYTES);

      return `${value}KB`;
    }

    const value = stripTrailingZeroIfExists(
      (fileSizeInBytes / MEGABYTE_BYTES).toFixed(1)
    );

    return `${value}MB`;
  },
  timestampUtc(date: Date): string {
    const utcHour = date.getUTCHours();
    const utcMinutes = date.getUTCMinutes();
    const utcSeconds = date.getUTCSeconds();

    return `${this.dateUtc(date)} ${utcHour}:${utcMinutes}:${utcSeconds} UTC`;
  },
  language(language: ILib.Language): string {
    const languageMap: Record<ILib.Language, string> = {
      ES: "Spanish",
      EN: "English",
      DE: "German",
      FR: "French",
    };

    return languageMap[language];
  },
  titleCase(str: string): string {
    return str.toLowerCase().replace(/^./, (char) => char.toUpperCase());
  },
};
