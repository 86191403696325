/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import * as xstate from "xstate";
import * as amplify from "aws-amplify";
import type * as IAuth from "@aws-amplify/auth";

interface Context {
  user: null | IAuth.CognitoUser;
  error: string;
  initialAuthCheck: boolean;
}

interface StateSchema {
  states: {
    unauthenticated: {};
    checkingAuthentication: {};
    authenticated: {};
  };
}

interface SetUser {
  type: "SET_USER";
  user: IAuth.CognitoUser | null;
}

interface ClearUser {
  type: "CLEAR_USER";
}

interface CheckAuthentication {
  type: "CHECK_AUTHENTICATION";
}

type DoneCheckingAuthentication = xstate.DoneInvokeEvent<IAuth.CognitoUser>;

type Event =
  | SetUser
  | ClearUser
  | CheckAuthentication
  | DoneCheckingAuthentication;

const setUser = xstate.assign<Context, Event>({
  user: (_context, event) => {
    const { data } = event as DoneCheckingAuthentication;

    return data;
  },
  initialAuthCheck: () => {
    return false;
  },
});

const setError = xstate.assign<Context, Event>({
  error: (context, event) => {
    if (context.initialAuthCheck) {
      return "";
    }

    const { data } = event as xstate.ErrorExecutionEvent;

    return data;
  },
});

const clearError = xstate.assign<Context, Event>({
  error: (_context, _event) => {
    return "";
  },
});

const clearUser = xstate.assign<Context, Event>({
  user: (_context, _event) => {
    return null;
  },
});

const checkAuthentication = async () => {
  return amplify.Auth.currentAuthenticatedUser();
};

// TODO make this validation more stringent
const validUser = (_context: Context, event: Event): boolean => {
  const { user } = event as SetUser;

  return user !== null;
};

const checkedAuthenticationMoreThanOnce = (context: Context) => {
  return !context.initialAuthCheck;
};

export const Authenticator = xstate.Machine<Context, StateSchema, Event>(
  {
    id: "authenticator",
    initial: "checkingAuthentication",
    context: { user: null, error: "", initialAuthCheck: true },
    states: {
      checkingAuthentication: {
        invoke: {
          src: "checkAuthentication",
          onDone: { actions: ["setUser"], target: "authenticated" },
          onError: [
            {
              actions: ["setError"],
              target: "unauthenticated",
              // we don't want to set the error when first loading the page
              cond: "checkedAuthenticationMoreThanOnce",
            },
            { target: "unauthenticated" },
          ],
        },
      },
      unauthenticated: {
        on: {
          SET_USER: {
            target: "authenticated",
            actions: ["setUser"],
            cond: "validUser",
          },
          CHECK_AUTHENTICATION: { target: "checkingAuthentication" },
        },
      },
      authenticated: {
        on: {
          CLEAR_USER: { target: "unauthenticated", actions: ["clearUser"] },
        },
      },
    },
  },
  {
    actions: { setUser, setError, clearError, clearUser },
    services: { checkAuthentication },
    guards: { validUser, checkedAuthenticationMoreThanOnce },
  }
);
