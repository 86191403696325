/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

import * as React from "react";
import * as xstate from "@xstate/react";
import * as amplify from "aws-amplify";

import * as machines from "src/machines";
import * as contexts from "src/contexts";

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [current, send] = xstate.useMachine(machines.Authenticator);

  return (
    <contexts.auth.Provider
      value={{
        login: () => amplify.Auth.federatedSignIn(),
        logout: amplify.Auth.signOut,
        setUser: (user) => {
          send({ type: "SET_USER", user });
        },
        checkAuthentication: () => {
          send({ type: "CHECK_AUTHENTICATION" });
        },
        clearUser: () => {
          send({ type: "CLEAR_USER" });
        },
        user: current.context.user as contexts.CognitoUser,
        error: current.context.error,
        status: current.value,
      }}
    >
      {children}
    </contexts.auth.Provider>
  );
};
