/*
© 2021 Amazon Web Services, Inc. or its affiliates. All Rights Reserved.

This AWS Content is provided subject to the terms of the AWS Customer Agreement
available at http://aws.amazon.com/agreement or other written agreement between
Customer and either Amazon Web Services, Inc. or Amazon Web Services EMEA SARL or both.
*/

// TODO add more locked down type that only JSON can support
export const commaDelimitedStringToJson = (
  commaDelimitedString: string
): string[] => {
  const splitByCommas = commaDelimitedString.split(",");

  if (commaDelimitedString === "") {
    return [];
  }

  return splitByCommas.map((value) => {
    return value.trim();
  });
};
